.nav-menu {
	z-index: 15;
	width: 0px;
	height: 100vh;
	transition: 850ms;
}

.nav-menu.active {
	z-index: 15;
	width: 200px;
	transition: 350ms;
}
