
.TestWindow {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #000000;
  background-color: white;
}

.CatsAndDogs {
  width: 1000px;
  height: 750px;
}

.Anart {
  width: 1000px;
  height: 400px;
}

body {
  background-color: lightblue;
}
/* html, body { overflow: hidden } */
